$(document).ready(function() {

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    // $('a[href*="#"]:not([href="#"])').click(function() {
    //     if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
    //         var target = $(this.hash);
    //         target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
    //         if (target.length) {
    //             scrollTo(target);
    //         return false;
    //         }
    //     }
    // });
    //
    // function scrollTo(element) {
    //     $('html, body').animate({
    //         scrollTop: element.offset().top
    //     }, 1000);
    // }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 100; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav button
    // *
    // *
    $(".js-nav-button").click(function() {
        $(this).toggleClass("active");
        $(".js-nav-mobile").toggleClass("active inactive");
        $("body").toggleClass("freeze");
    })

    $(".js-nav-mobile li a").click(function() {
        $(".js-nav-button").trigger("click");
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * textSlider
    // *
    // *
	var $textSlider = $(".js-text-slider");

	if ($textSlider.length){
		$textSlider.each(function(){

            var $sliderWrapper = $(this).parents(".js-slider-wrapper");

			$(this).slick({
				fade: true,
				dots: true,
				infinite: false,
				arrows: true,
				autoplay: false,
				speed: 1000,
				appendDots: $sliderWrapper.find(".js-slider-dots"),
				prevArrow: $sliderWrapper.find(".js-slider-prev"),
				nextArrow: $sliderWrapper.find(".js-slider-next"),
			});

            // set total
            var total = $(this).find(".slick-slide:not(.slick-cloned)").length;
            $sliderWrapper.find(".js-slider-total").html(pad(total, 2));

            // set current number, description and fullscreen
            $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
                $sliderWrapper.find(".js-slider-current").html(pad((nextSlide + 1), 2));
            });
		});
	}

    // formatting numbers to n digits: 1 => 01
    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teamSlider
    // *
    // *
	var $teamSlider = $(".js-team-slider");

	if ($teamSlider.length){
		$teamSlider.each(function(){

            var $sliderWrapper = $(this).parents(".js-slider-wrapper");

			$(this).slick({
				fade: false,
				dots: true,
				infinite: false,
				arrows: true,
				autoplay: false,
				speed: 1000,
                slidesToShow: 3,
				appendDots: $sliderWrapper.find(".js-slider-dots"),
				prevArrow: $sliderWrapper.find(".js-slider-prev"),
				nextArrow: $sliderWrapper.find(".js-slider-next"),
                responsive: [
                        {
                            breakpoint: 900,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                ]
			});

            // set total
            var total = $(this).find(".slick-slide:not(.slick-cloned)").length;
            $sliderWrapper.find(".js-slider-total").html(pad(total, 2));

            // set current number, description and fullscreen
            $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
                $sliderWrapper.find(".js-slider-current").html(pad((nextSlide + 1), 2));
            });
		});
	}





    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-button").click(function() {
        if($(this).hasClass("active")) {
            $(this).removeClass("active").siblings().slideUp();
        } else {
            $(this).parents(".accordion__wrapper").find(".js-accordion-button").removeClass("active").siblings().slideUp();
            $(this).addClass("active").siblings().slideDown();
        }
    })


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Nav Active when Element is in Viewpoint
    // *
    // *
    var $sections = $(".js-nav-section"),
        $nav = $(".js-nav"),
        navHeight = 0; //$(".js-header").outerHeight();

    $(window).on('scroll', function () {
        var cur_pos = $(this).scrollTop() + 120;

        $sections.each(function() {
            var top = $(this).offset().top - navHeight, bottom = top + $(this).outerHeight();

            if (cur_pos >= top && cur_pos <= bottom) {
                $nav.find('li').removeClass('active');
                $sections.removeClass('active');
                $(this).addClass('active');
                $nav.find('a[href="/de/#'+$(this).attr('id')+'"]').parent('li').addClass('active');
                $nav.find('a[href="/en/#'+$(this).attr('id')+'"]').parent('li').addClass('active');
            }
        });

        if ($(window).scrollTop() == 0) {
            $(".js-intro-image").removeClass("active");
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * form
    // *
    // *
    $(".js-form-button").click(function() {
        if($(".js-form").hasClass("active")) {
            $(".js-form").removeClass("active");
            $("body").removeClass("freeze");
        } else {
            $(".js-form").addClass("active");
            $("body").addClass("freeze");
        }
    })

    
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * typewriter
    // *
    // *
    var $typewriter = $(".js-typewriter");

    if ($typewriter.length) {

        $typewriter.each(function(){
            var item = $(this);
            var text = $(this).data("html");
            var offset = $(this).data("offset");

            function typeName(element, name, iteration) {
                // Prevent our code executing if there are no letters left
                if (iteration === name.length) {
                    return;
                }

                setTimeout(function() {
                    // Set the name to the current text + the next character
                    // whilst incrementing the iteration variable
                    element.text( element.text() + name[iteration++] );

                    // Re-trigger our function
                    typeName(element, name, iteration);
                }, 100);
            }

            setTimeout(function() {
                typeName(item, text, 0);
            }, offset)
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * typewriter
    // *
    // *   
    var mixer = mixitup('.js-filter-container', {
        selectors: {
            target: '.js-filter-element'
        },
        load: {
            filter: '.all'
        }
    });

});


// * * * * * * * * * * * * * * * * * * * * * * * * *
// * paroller
// *
// *
// scroll to top so that paroller is working 100%
$(window).on('beforeunload', function(){
    $(this).scrollTop(0);
});

$(window).on('load resize', function () {
    if ($(window).width() > 1300) {
        $("[data-paroller-factor]").paroller();
    }

    $(window).scrollTop($(window).scrollTop()+1);
});

$(window).on('resize', function () {
    if ($(window).width() > 1300) {
        location.reload();
    }
});
